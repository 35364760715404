<template>
  <div class="row">
    <div class="col-12 d-flex justify-content-between align-items-center">
      <h4>Attorneys</h4>

      <div class="dropdown d-flex">
        <button class="btn btn-info btn-sm" type="button" @click="toggleAddPopup">
          <i class="fas fa-plus me-2"></i>Add Attorney
        </button>
        <OverlayPanel ref="op">
          <label for="">New Attorney</label>
          <input type="text" class="form-control" v-model="name" />
          <div class="d-flex">
            <button class="btn btn-outline-secondary btn-sm mt-3" @click="toggleAddPopup">Cancel</button> &nbsp;
            <button class="btn btn-primary btn-sm mt-3" @click="addAttorney">Save</button>
          </div>
        </OverlayPanel>
      </div>
    </div>

    <div class="col-12 mt-4 w-100">
      <div class="row justify-content-end mb-3">
        <div class="col-6 m-0 p-0">
          <input type="text" class="form-control" placeholder="Search" v-model="filters['global'].value" />
        </div>
      </div>

      <DataTable
        :value="attorneys"
        responsiveLayout="scroll"
        removableSort
        editMode="row"
        v-model:editingRows="editingRows"
        :loading="loading"
        @row-edit-save="onRowEditSave"
        v-model:filters="filters"
      >
        <Column field="name" header="Name" :sortable="true">
          <template #editor="{ data, field }">
            <input class="form-control" v-model="data[field]" autofocus />
          </template>
        </Column>

        <Column field="created_at" header="Created" :sortable="true">
          <template #body="{ data }">
            {{ formatDate(data.created_at) }}
          </template>
        </Column>

        <Column field="updated_at" header="Updated" :sortable="true">
          <template #body="{ data }">
            {{ formatDate(data.updated_at) }}
          </template>
        </Column>

        <Column :rowEditor="true"></Column>
        <Column>
          <template #body="{ data }">
            <button class="btn" @click="(event) => deleteAttorney(event, data)"><i class="pi pi-trash"></i></button>
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'

import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import OverlayPanel from 'primevue/overlaypanel'
import ConfirmPopup from 'primevue/confirmpopup'
import { useToast } from 'primevue/usetoast'
import { useConfirm } from 'primevue/useconfirm'

import AttorneyService from '../../../services/attorney.service.js'
import { useDatatable } from '../../../hooks/useDatatable.js'

export default {
  components: {
    DataTable,
    Column,
    OverlayPanel,
    ConfirmPopup,
  },
  setup() {
    const attorneys = ref([])
    const op = ref()
    const name = ref('')
    const toast = useToast()
    const confirm = useConfirm()
    const { loading, filters, editingRows } = useDatatable()

    onMounted(() => {
      getAttorneys()
    })

    const getAttorneys = async () => {
      loading.value = true
      attorneys.value = await AttorneyService.fetchAll()
      loading.value = false
    }

    const addAttorney = async () => {
      if (name.value == '') return
      const newAttorney = await AttorneyService.createAttorney({ name: name.value })
      attorneys.value.push(newAttorney)
      name.value = ''
      toast.add({ severity: 'success', summary: 'Success', detail: 'Attorney added', life: 3000 })
      toggleAddPopup()
    }

    const deleteAttorney = (event, attorney) => {
      confirm.require({
        target: event.currentTarget,
        message: 'Do you want to delete this record?',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-danger',
        accept: async () => {
          await AttorneyService.deleteAttorney(attorney.id)
          attorneys.value = attorneys.value.filter((a) => a.id != attorney.id)
          toast.add({ severity: 'success', summary: 'Deleted', detail: `${attorney.name} deleted`, life: 3000 })
        },
      })
    }

    const formatDate = (date) => {
      return new Date(date).toLocaleDateString()
    }

    const onRowEditSave = async (event) => {
      const { newData } = event
      const updated = await AttorneyService.updateAttorney(newData.id, newData)
      attorneys.value = attorneys.value.map((a) => (a.id == updated.id ? updated : a))
    }

    const toggleAddPopup = (event) => {
      op.value.toggle(event)
    }

    return {
      attorneys,
      formatDate,
      op,
      toggleAddPopup,
      addAttorney,
      name,
      loading,
      filters,
      editingRows,
      onRowEditSave,
      deleteAttorney,
    }
  },
}
</script>
