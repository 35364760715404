<template>
  <div class="row w-100 m-0 text-start">
    <div class="col-12 mb-2">
      <h2>Deal Settings</h2>
    </div>
    <div class="row text-center justify-content-center">
      <div class="d-flex align-items-start col-10">
        <div class="nav flex-column nav-pills me-3" id="v-pills-tab" role="tablist" aria-orientation="vertical" style="height: 100%;border-right: 1px solid #eee;">
          <button
            class="nav-link ws-nowrap active"
            id="v-pills-attorney-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-attorney"
            type="button"
            role="tab"
            aria-controls="v-pills-attorney"
            aria-selected="true"
          >
            Attorneys
          </button>
          <button
            class="nav-link ws-nowrap"
            id="v-pills-agency-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-agency"
            type="button"
            role="tab"
            aria-controls="v-pills-agency"
            aria-selected="false"
          >
            Escrow Agencies
          </button>
          <button
            class="nav-link ws-nowrap"
            id="v-pills-notary-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-notary"
            type="button"
            role="tab"
            aria-controls="v-pills-notary"
            aria-selected="false"
          >
            Notaries
          </button>
          <button
            class="nav-link ws-nowrap"
            id="v-pills-source-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-source"
            type="button"
            role="tab"
            aria-controls="v-pills-source"
            aria-selected="false"
          >
            Sources
          </button>
        </div>
        <div class="tab-content w-100" id="v-pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="v-pills-attorney"
            role="tabpanel"
            aria-labelledby="v-pills-attorney-tab"
          >
            <AttorneysTab></AttorneysTab>
          </div>
          <div class="tab-pane fade" id="v-pills-agency" role="tabpanel" aria-labelledby="v-pills-agency-tab">
            <EscrowAgenciesTab></EscrowAgenciesTab>
          </div>
          <div class="tab-pane fade" id="v-pills-notary" role="tabpanel" aria-labelledby="v-pills-notary-tab">
            <NotariesTab></NotariesTab>
          </div>
          <div class="tab-pane fade" id="v-pills-source" role="tabpanel" aria-labelledby="v-pills-source-tab">
            <SourcesTab></SourcesTab>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SourcesTab from './components/SourcesTab.vue'
import AttorneysTab from './components/AttorneysTab.vue'
import EscrowAgenciesTab from './components/EscrowAgenciesTab.vue'
import NotariesTab from './components/NotariesTab.vue'
export default {
  components: {
    AttorneysTab,
    EscrowAgenciesTab,
    NotariesTab,
    SourcesTab,
  },
  setup() {},
}
</script>
